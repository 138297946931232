import { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { StyledDashboardContentWrapper } from "../../../components/common/Basics/DashboardContentWrapper";
import { StyledDivFlex } from "../../../components/common/Basics/DivFlex";
import { StyledPageHeaderButton } from "../../../components/common/Basics/PageHeaderButton";
import { StyledButton } from "../../../components/common/Button/style";
import Dropdown from "../../../components/common/Dropdown";
import PageHeadingButtons from "../../../components/common/PageButton";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import PageHeaderLayout from "../../../components/Layouts/HeaderLayout";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { StyledBox } from "../../../components/common/Basics/DivBox";

import { StyledText } from "../../../components/common/Basics/StyledText";
import AvailabilityGraph from "./AvailabilityCard/Graph";
import WidgetMenu from "../../../components/Widget/MiniDropDown";

import WidgetWithDropdown from "../../../components/Widget/WidgetWithDropdown";
import { generalDashbordCardItem } from "../../../DUMMYDATA";
import AvailabilityCard from "./AvailabilityCard";
import PickDate from "../../../components/common/DatePicker";
import SubHeaderLayout from "../../../components/Layouts/SubHeaderLayout";
import { widgetComponents } from "../../../constants";
import { widgetContext } from "../../../Context/WidgetContext";
import { StyledDivGrid } from "../../../components/common/Basics/DivGrid";
import ModifiedCard from "../../../components/Widget/WidgetWithDropdown/ModifiedCard";
import BusyOverlay from "../../../components/common/BusyOverlay";
import { useUpdateWidget } from "./hooks/useUpdateWidget";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { formatDate } from "../../../utils/FormatDate";
import { useFilterGraph } from "../../../hooks/useGraphFilter";
import MapTokenToUser from "../../../Authorization/MapTokenToUser";
import { useGetCSVExport } from "../../../hooks/useGetCSVExport";
import CalendarCheck from "../../../components/Widget/Calendar";
import { getYears } from "../../../utils/GetYears";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Theme } from "../../../Theme";
import { ToastContainer, toast } from "react-toastify";

const GeneralDashboard = () => {
  const toastId = useRef(null);
  const [widgets, setWidgets] = useState();
  const [currentYear, setCurrentYear] = useState("2023");
  const { getCSVExport, csvData, isDownloading, isExporting } =
    useGetCSVExport();

  const filterData = () =>
    (toastId.current = toast.info("♻ Filtering", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }));

  const resetData = () =>
    (toastId.current = toast.info("♻ Updating all", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }));

  const closeToast = () => toast.dismiss(toastId.current);

  const user = MapTokenToUser();
  console.log(user.user.data.role);
  // DEBUG
  // console.log("user", user.user.data.role);

  const {
    updateWidget,
    isLoading: isWidgetUpdating,
    updateWidgetdata,
  } = useUpdateWidget();

  const {
    widgets: widgetsData,
    widgetsDropdownData,
    isLoading,
    dateFilter,
    setDateFilter,
    dateRange,
    setDateRange,
    getWidgets,
    data,
    date1,
    date2,
    companyId,
    setCompanyId,
  } = useContext(widgetContext);

  const [companyFilter, setCompanyFilter] = useState(
    `company=${user?.user.Company.id && user?.user.Company?.id}`
  );

  useFilterGraph({ dateFilter, companyFilter, getData: getWidgets });

  const addWidget = ({ widget }) => {
    const allWidgets = [...widgets];
    if (!allWidgets.includes(widget)) {
      allWidgets.push(widget);
    }

    const data = {
      widgets_to_show: allWidgets,
    };

    updateWidget(data);
  };

  const removeWidget = (widget) => {
    const allWidgets = [...widgets];
    const updatedWidget = allWidgets.filter(
      (widgetName) => widgetName !== widget
    );
    const data = {
      widgets_to_show: updatedWidget,
    };
    updateWidget(data);
  };

  const widgetComponents = [
    {
      widgetName: "operators",
      Component: (
        <WidgetWithDropdown
          // label="Operators (Active)"
          label="Operators"
          count={Math.round(widgetsData?.no_of_operators).toLocaleString()}
          onRemove={() => removeWidget("operators")}
          report={<Link to="/driver">View Report</Link>}
        />
      ),
    },
    {
      widgetName: "trucks",
      Component: (
        <WidgetWithDropdown
          // label="Trucks (Active)"
          label="Trucks"
          count={Math.round(widgetsData?.no_of_trucks).toLocaleString()}
          onRemove={() => removeWidget("trucks")}
          report={<Link to="/truck-management">View Report</Link>}
        />
      ),
    },
    {
      widgetName: "uptime",
      Component: (
        <WidgetWithDropdown
          label="Overall Uptime (Hours)"
          count={Math.round(widgetsData?.total_uptime).toLocaleString()}
          onRemove={() => removeWidget("uptime")}
          report={<Link to="/monthly-avaliablity">View Report</Link>}
        />
      ),
    },
    // {
    //   widgetName: "shock",
    //   Component: (
    //     <ModifiedCard
    //       label="Highest ShockThreshold"
    //       count={Math.round(widgetsData?.total_no_of_shocks).toLocaleString()}
    //       report={<Link to="/shocking-sense">View Report</Link>}
    //       thresholdCount={Math.round(
    //         widgetsData?.highest_shock_threshold
    //       ).toLocaleString()}
    //       onRemove={() => {
    //         removeWidget("shock");
    //       }}
    //     />
    //   ),
    // },
  ];

  if (user.user.data.role !== "user") {
    widgetComponents.push({
      widgetName: "carbon",
      Component: (
        <WidgetWithDropdown
          label="Co2 Reduction (Tons)"
          count={widgetsData?.total_co2_reduction_in_ton.toFixed(0).toString()}
          onRemove={() => removeWidget("carbon")}
          report={<Link to="/co2-reduction">View Report</Link>}
        />
      ),
    });
  }

  useEffect(() => {
    if (widgetsData) {
      const { widgets_to_show } = widgetsData;
      setWidgets(widgets_to_show);
    }
  }, [widgetsData]);

  useEffect(() => {
    if (updateWidgetdata) {
      setWidgets(updateWidgetdata);
    }
  }, [updateWidgetdata]);

  return (
    <DashboardLayout>
      {/* <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
      <StyledDashboardContentWrapper>
        <PageHeaderLayout>
          <StyledDivFlex gap="0.8rem">
            {/* <StyledPageHeaderButton
              onClick={() => {
                const user = MapTokenToUser();
                console.log("user export", user.user.email);
                const data = {
                  export: {
                    entity: "dashboard",
                    query: {},
                    as: "email",
                    recipients: [user.user.email],
                  },
                };

                getCSVExport(data);
              }}
            >
              {isExporting ? "Sending..." : " Send Report to my Email"}
            </StyledPageHeaderButton> */}
            {/* <StyledPageHeaderButton
              onClick={() => {
                const data = {
                  export: {
                    entity: "dashboard",
                    query: {},
                    as: "download",
                  },
                };

                getCSVExport(data);
              }}
            >
              {isDownloading ? "DownLoading" : "Download Report"}
            </StyledPageHeaderButton> */}
          </StyledDivFlex>
        </PageHeaderLayout>
        <StyledDivFlex
          // background={Theme.colors.neutralColor}
          padding="0.8rem 6.4rem"
          marginTop="0.3rem"
          justifyContent="flex-end"
          gap="1rem"
          alignItems="center"
          paddingM="1rem 0"
          gapM="1.5rem"
          justifyContentM="center"
          flexDirectionS="column"
        >
          <Dropdown
            // background={Theme.colors.secondaryColor}
            name="widget"
            label="Add Widget"
            onChange={(data) => {
              addWidget(data);
            }}
            data={
              user.user.data.role === "user"
                ? widgetsDropdownData?.filter(
                    (item) =>
                      item.toLowerCase() !== "carbon" &&
                      item.toLowerCase() !== "calendar"
                  )
                : widgetsDropdownData?.filter(
                    (item) => item.toLowerCase() !== "calendar"
                  )
            }
            capitalize
            gap="1.6rem"
            minWidth="20rem"
            widthS="90%"
            icon={
              <AddBoxOutlinedIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
          />
          {/* <PickDate
            onChange={(date) => {
              const filter =
                date &&
                `period[start]=${
                  formatDate(date[0])["yyyy-mm-dd"]
                }&period[end]=${formatDate(date[1])["yyyy-mm-dd"]} 
             `;
              setDateFilter(filter);
              setDateRange(date);
            }}
          /> */}

          <Dropdown
            // background={Theme.colors.secondaryColor}
            heightFactor={2}
            name="year"
            label="Filter Year"
            onChange={(data) => {
              if (!data.year) data.year = 2023;
              filterData();
              const { year } = data;
              setCurrentYear(year);
              const date1 = `${year}-01-01`;
              const date2 = `${year}-12-31`;
              const filter =
                year && `period[start]=${date1}&period[end]=${date2}`;
              console.log("kudos", filter);
              setDateFilter(filter);
              setDateRange([date1, date2]);
              let closeTimeout = setTimeout(() => {
                closeToast();
                clearTimeout(closeTimeout);
              }, 1000);
              // window.location.reload();
            }}
            data={getYears(2021, new Date().getFullYear())}
            gap="1.6rem"
            minWidth="16rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
          />

          {user.user.data.role === "personnel" && (
            <StyledButton
              background={Theme.colors.primaryColor}
              color={Theme.colors.secondaryColor}
              padding="0.8rem"
              borderRadius="0.8rem"
              widthS="90%"
              minWidth="16rem"
              onClick={() => {
                resetData();
                const filter = `company=all`;
                // const dateFilter = `period[start]=${"startDate"}}
                // &period[end]=${"endDate"}${companyId==="all"?"":`&company=${"all"}`}`
                // setCompanyId("all");
                setCompanyFilter(filter);
                let closeTimeout = setTimeout(() => {
                  closeToast();
                  clearTimeout(closeTimeout);
                }, 1000);
                // setDateFilter(dateFilter)
              }}
            >
              All Clients
            </StyledButton>
          )}
        </StyledDivFlex>

        <StyledBox
          // padding="1rem 8rem"
          marginTop="0rem"
          // width="100%"
          paddingS="0"
          widthS="100%"
          position="relative"
        >
          <SubHeaderLayout
            text="General Dashboard Managment:"
            dateRange={dateRange}
            // count={data?.length}
            showYear={formatDate(dateRange[0])["yyyy"]}
            data={widgetsData}
          />
          <StyledDivGrid
            padding="0.8rem 6.4rem"
            marginTop="0rem"
            gap="1.6rem"
            width="100%"
            paddingS="2rem"
          >
            {/* {widgets?.map((item) => {
              return <>{getWidget(item)}</>;
            })} */}
            {widgetComponents
              ?.reverse()
              .map(
                (widget, index) =>
                  widgets?.includes(widget.widgetName) && (
                    <span key={Math.random().toString()}>
                      {widget.Component}
                    </span>
                  )
              )}
            {isLoading &&
              !widgetsData &&
              Array.from(Array(3).keys()).map((item, index) => (
                <Skeleton
                  style={{
                    height: "20rem",
                    width: "100%",
                    borderRadius: "1.6rem",
                  }}
                  key={index.toString()}
                />
              ))}{" "}
            {widgets?.includes("monthly_availability") && (
              <StyledBox gridColumn={"span 2"} gridColumnM="span 1">
                <AvailabilityCard
                  onRemove={() => removeWidget("monthly_availability")}
                  data={data}
                  year={currentYear}
                />
              </StyledBox>
            )}
            {/* {widgets?.includes("calendar") && (
              <CalendarCheck onRemove={() => removeWidget("calendar")} />
            )} */}
          </StyledDivGrid>
          <StyledDivFlex gap="2.4rem" marginTop="3.2rem"></StyledDivFlex>
        </StyledBox>
      </StyledDashboardContentWrapper>
      {/* <BusyOverlay
        isLoading={isWidgetUpdating || isLoading}
        spinnerText="Updating widgets..."
      /> */}
    </DashboardLayout>
  );
};

export default GeneralDashboard;
