import React, { useContext, useEffect, useState, useRef } from "react";

import { StyledDashboardContentWrapper } from "../../../components/common/Basics/DashboardContentWrapper";
import { StyledDivFlex } from "../../../components/common/Basics/DivFlex";
import { StyledPageHeaderButton } from "../../../components/common/Basics/PageHeaderButton";
import Dropdown from "../../../components/common/Dropdown";
import PageHeadingButtons from "../../../components/common/PageButton";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import PageHeaderLayout from "../../../components/Layouts/HeaderLayout";
import { locations, period, trucks } from "../../../DUMMYDATA";
import { Theme } from "../../../Theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SubHeaderLayout from "../../../components/Layouts/SubHeaderLayout";
import { StyledBox } from "../../../components/common/Basics/DivBox";
import { batteryUsage } from "../../../DUMMYDATACHART.js";
import { StyledText } from "../../../components/common/Basics/StyledText";
import BatteryGraph from "./BatteryGraph";
import { useGetCSVExport } from "../../../hooks/useGetCSVExport";
import { dropdownFilterContext } from "../../../Context/DropdownFiltersContext";
import { useGetBattery } from "./hooks/useGetBattery";
import { getPreviousDate, getTodayDate } from "../../../utils/GetDate";
import { formatDate } from "../../../utils/FormatDate";
import PickDate from "../../../components/common/DatePicker";
import { useFilterGraph } from "../../../hooks/useGraphFilter";
import SpinnerWithText from "../../../components/common/SpinnerWithText";
import MapTokenToUser from "../../../Authorization/MapTokenToUser";
import SingleDatePicker from "../../../components/common/SingleDatePicker";
import { batteryInterval } from "../../../constants/DropDownData";

const Battery = () => {
  const { getBattery, data, error, isLoading } = useGetBattery();

  const [selectedLocations, setSelectedLocations] = useState([]);

  const [startDate, setStartDate] = useState(getTodayDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [truckDownload, setTruckDownload] = useState();
  const [locationDownload, setLocationDownload] = useState();

  const filter = `period[start]=${startDate}&period[end]=${endDate}`;
  const [dateRange, setDateRange] = useState([startDate, endDate]);

  const [truckFilter, setTruckFilter] = useState();
  const [currentTruck, setCurrentTruck] = useState("");
  const [locationFilter, setLocationFilter] = useState();
  const [intervalFilter, setIntervalFilter] = useState("interval=minute");
  const [dateFilter, setDateFilter] = useState(filter);
  const { getCSVExport, csvData, isDownloading, isExporting } =
    useGetCSVExport();

  const { truckDropdownData, locationsDropdownData } = useContext(
    dropdownFilterContext
  );

  // useEffect(() => {
  //   getBattery(filter);
  // }, []);
  useEffect(() => {
    if (truckDropdownData.length && locationsDropdownData.length) {
      let firstLocation = locationsDropdownData[0];
      let match = truckDropdownData.find(
        (truck) => truck?.city.toLowerCase() === firstLocation.toLowerCase()
      );
      console.log(match);
      if (match) {
        setTruckFilter(`truck=${match?.truck}`);
        setCurrentTruck(match?.truck);
      }
      // setTruckFilter(`truck=${truckDropdownData[0]?.truck}`);
      // setCurrentTruck(truckDropdownData[0]?.truck);
    }
  }, [truckDropdownData, locationsDropdownData]);

  useEffect(() => {
    if (locationsDropdownData.length) {
      setLocationFilter(`location=${locationsDropdownData[0]}`);
    }
  }, [locationsDropdownData]);

  useFilterGraph({
    truckFilter,
    locationFilter,
    dateFilter,
    intervalFilter,
    getData: getBattery,
  });

  const modalRef = useRef(null);
  const [action, setAction] = useState("");

  const closeModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "none";
  };

  const showModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "grid";
  };

  return (
    <DashboardLayout>
      {/* BATTERY MODAL */}

      <div
        ref={modalRef}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "none",
          placeItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9000,
        }}
      >
        <div
          style={{
            width: 450,
            height: 450,
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: "3rem",
          }}
        >
          <p
            style={{
              fontSize: "2.5rem",
              color: "#000",
              marginBottom: "1.5rem",
              fontWeight: "bolder",
            }}
          >
            {action === "exporting" ? "Send" : "Download"} report
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            From: {dateFilter.split("&")[0].replace("period[start]=", "")}
          </p>

          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            To: {dateFilter.split("&")[1].replace("period[end]=", "")}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            For{" "}
            {truckFilter
              ? truckFilter.replace("truck=", "").split(",").length > 1
                ? `trucks: ${truckFilter
                    .replace("truck=", "")
                    .split(",")
                    .join(", ")}`
                : `truck: ${truckFilter.replace("truck=", "")}`
              : "all trucks"}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            At{" "}
            {locationFilter
              ? `${
                  locationFilter.replace("location=", "").split(",").length > 1
                    ? `locations: ${locationFilter
                        .replace("location=", "")
                        .split(",")
                        .join(", ")}`
                    : `location: ${locationFilter.replace("location=", "")}`
                }`
              : "all locations."}
          </p>
          <div className="action" style={{ marginTop: "2rem" }}>
            {action === "exporting" ? (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const user = MapTokenToUser();
                  const data = {
                    export: {
                      entity: "battery_management",
                      query: {
                        period: {
                          start: startDate,
                          end: endDate,
                        },
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "email",
                      recipients: [user.user.email],
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Send
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const data = {
                    export: {
                      entity: "battery_management",
                      query: {
                        period: {
                          start: startDate,
                          end: endDate,
                        },
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "download",
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Continue to download
              </button>
            )}
            <button
              style={{
                backgroundColor: "red",
                padding: "0.8rem 1.7rem",
                color: "#fff",
                fontFamily: "inherit",
                borderRadius: "0.4rem",
                minWidth: 120,
                fontSize: "1.4rem",
              }}
              onClick={(e) => closeModal(e)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      {/* BATTERY MODAL */}

      <StyledDashboardContentWrapper>
        <PageHeaderLayout>
          <StyledDivFlex
            gap="1rem"
            flexDirectionSd="column"
            widthSd="100%"
            paddingS="2rem"
          >
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("exporting");
              }}
            >
              {" "}
              {isExporting ? "Sending......" : " Send Report to my Email"}
            </StyledPageHeaderButton>
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("downloading");
              }}
            >
              {" "}
              {isDownloading
                ? "Downloading..."
                : `Download${
                    !locationFilter && !truckFilter
                      ? // &&
                        // dateFilter ===
                        //   `period[start]=${startDate}&period[end]=${endDate}`
                        " all "
                      : " "
                  }Report`}
            </StyledPageHeaderButton>
          </StyledDivFlex>
        </PageHeaderLayout>

        <StyledDivFlex
          // background={Theme.colors.neutralColor}
          padding="1rem 8rem"
          // marginTop="2rem"
          justifyContent="flex-end"
          gap="4rem"
          alignItems="center"
          paddingM="1rem 0"
          gapM="1.5rem"
          justifyContentM="center"
          flexDirectionS="column"
        >
          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            value={
              locationFilter?.includes("location=") &&
              locationFilter.replace("location=", "")
            }
            name="location"
            label="Location"
            onChange={(data) => {
              const { location } = data;
              const filter = location && `location=${location}`;
              setLocationFilter(filter);
              setLocationDownload(location);
              setSelectedLocations([...selectedLocations, location]);
            }}
            data={locationsDropdownData}
            gap="2rem"
            minWidth="20rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
          />
          {/* <PickDate
            onChange={(date) => {
              const filter =
                date &&
                `period[start]=${
                  formatDate(date[0])["yyyy-mm-dd"]
                }&period[end]=${formatDate(date[1])["yyyy-mm-dd"]} 
               `;
              setDateFilter(filter);
              setDateRange(date);
              setStartDate(formatDate(date[0])["yyyy-mm-dd"]);
              setEndDate(formatDate(date[1])["yyyy-mm-dd"]);
            }}
          /> */}

          <SingleDatePicker
            loading={isLoading}
            onChange={(date) => {
              const filterDate = date ? date : new Date();
              const filter =
                filterDate &&
                `period[start]=${
                  formatDate(filterDate)["yyyy-mm-dd"]
                }&period[end]=${formatDate(filterDate)["yyyy-mm-dd"]} 
               `;
              setDateFilter(filter);

              setDateRange([filterDate, filterDate]);
              setStartDate(formatDate(filterDate)["yyyy-mm-dd"]);
              setEndDate(formatDate(filterDate)["yyyy-mm-dd"]);
            }}
            widthS="90%"
          />

          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            value={
              truckFilter?.includes("truck=") &&
              truckFilter.replace("truck=", "")
            }
            name="truck"
            label="Filter Truck"
            onChange={(data) => {
              const { truck } = data;
              const filter = truck && `truck=${truck}`;
              setCurrentTruck((prev) => (truck ? truck : ""));
              setTruckFilter(filter);
              setTruckDownload(truck);
            }}
            data={truckDropdownData.filter((x) => {
              if (
                selectedLocations.includes(x.city) ||
                selectedLocations.length == 0
              ) {
                return x;
              }
            })}
            gap="2rem"
            minWidth="20rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
          />

          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            name="interval"
            label="Interval"
            onChange={(data) => {
              const { interval } = data;

              const filter = interval && `interval=${interval}`;
              setIntervalFilter(filter);

              // setLocationDownload(location);
            }}
            data={batteryInterval}
            gap="2rem"
            minWidth="20rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
          />
        </StyledDivFlex>
        <StyledBox background={Theme.colors.neutralColor}>
          {/* <SpinningLoader /> */}
          <SubHeaderLayout
            current={currentTruck}
            text="Energy for the period:"
            dateRange={dateRange}
          />

          {/* <StyledDivFlex
            padding="1rem 8rem"
            justifyContent="flex-end"
            gap="2rem"
          >
            <StyledDivFlex alignItems="center" gap="1rem">
              <StyledText
                height="2px"
                width="80px"
                background="#19A979"
              ></StyledText>
              <StyledText
                fontSize="1.4rem"
                fontWeight="500"
                color={Theme.colors.neutralColor2}
              >
                Low energy State
              </StyledText>
            </StyledDivFlex>

            <StyledDivFlex alignItems="center" gap="1rem">
              <StyledText
                height="2px"
                width="80px"
                background="#E8743B"
              ></StyledText>
              <StyledText
                fontSize="1.4rem"
                fontWeight="500"
                color={Theme.colors.neutralColor2}
              >
                Critical energy state
              </StyledText>
            </StyledDivFlex>
          </StyledDivFlex> */}

          {/* BARCHART STARTS FROM HERE  */}
          {isLoading ? (
            <SpinnerWithText isLoading={isLoading} margin="2rem 0 0 0" />
          ) : (
            <BatteryGraph
              data={data}
              isLoading={isLoading}
              current={currentTruck}
            />
          )}

          {/* BARCHART ENDS HERE */}
        </StyledBox>
      </StyledDashboardContentWrapper>
    </DashboardLayout>
  );
};

export default Battery;
