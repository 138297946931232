import React, { useContext, useEffect, useState, useRef } from "react";
import { StyledDashboardContentWrapper } from "../../../components/common/Basics/DashboardContentWrapper";
import { StyledDivFlex } from "../../../components/common/Basics/DivFlex";
import { StyledPageHeaderButton } from "../../../components/common/Basics/PageHeaderButton";
import Dropdown from "../../../components/common/Dropdown";
import PageHeadingButtons from "../../../components/common/PageButton";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import PageHeaderLayout from "../../../components/Layouts/HeaderLayout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Theme } from "../../../Theme";
import { period, locations, trucks } from "../../../DUMMYDATA";
import SubHeaderLayout from "../../../components/Layouts/SubHeaderLayout";
import SpinningLoader from "../../../components/common/SpinningLoader";
import { StyledText } from "../../../components/common/Basics/StyledText";
import { StyledBox } from "../../../components/common/Basics/DivBox";
import { dummyDataChart } from "../../../DUMMYDATACHART.js";
import { useMonthlyAvaliablity } from "./hooks/useMonthlyAvaliablity";
import AvaliablityGraph from "./Graph";
import { dropdownFilterContext } from "../../../Context/DropdownFiltersContext";
import { useFilterGraph } from "../../../hooks/useGraphFilter";
import PickDate from "../../../components/common/DatePicker";
import { formatDate } from "../../../utils/FormatDate";
import { useGetCSVExport } from "../../../hooks/useGetCSVExport";
import { getPreviousDate, getTodayDate } from "../../../utils/GetDate";
import DailyRating from "./DailyRatings";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { StyledDivGrid } from "../../../components/common/Basics/DivGrid";
import MapTokenToUser from "../../../Authorization/MapTokenToUser";
import {
  monthLookUp,
  months,
  quarter,
  quarterLookUp,
} from "../../../constants/DropDownData";
import { getYears } from "../../../utils/GetYears";
import { StyledButton } from "../../../components/common/Button/style";

const MonthlyAvaliablity = () => {
  const { getMonthlyAvaliablity, data, error, isLoading, summary } =
    useMonthlyAvaliablity();

  const [startDate, setStartDate] = useState(getPreviousDate(31));
  const [endDate, setEndDate] = useState(getTodayDate());
  const [truckDownload, setTruckDownload] = useState();
  const [locationDownload, setLocationDownload] = useState();

  const [selectedLocations, setSelectedLocations] = useState([]);

  const filter = `period[start]=${startDate}&period[end]=${endDate}`;
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [truckFilter, setTruckFilter] = useState();
  const [currentTruck, setCurrentTruck] = useState("");
  const [trackMonth, setTrackMonth] = useState("");
  const [trackQuarter, setTrackQuarter] = useState("");
  const [trackYear, setTrackYear] = useState("");
  const [locationFilter, setLocationFilter] = useState(``);
  const [dateFilter, setDateFilter] = useState(filter);
  const { getCSVExport, csvData, isDownloading, isExporting } =
    useGetCSVExport();

  const { truckDropdownData, locationsDropdownData } = useContext(
    dropdownFilterContext
  );

  useEffect(() => {
    if (truckDropdownData.length && locationsDropdownData.length) {
      let firstLocation = locationsDropdownData[0];

      // let match = truckDropdownData.find(
      //   (truck) => truck?.city.toLowerCase() === firstLocation.toLowerCase()
      // );
      let match = truckDropdownData.filter(
        (truck) => truck?.city.toLowerCase() === firstLocation.toLowerCase()
      );

      if (match.length > 0) {
        let coupledTrucks = match
          .map((item) => (item?.truck ? item.truck : ""))
          .join(",");
        // console.log(coupledTrucks);
        setTruckFilter(`truck=${coupledTrucks}`);
        setCurrentTruck(coupledTrucks);
      }
      // setTruckFilter(`truck=${truckDropdownData[0]?.truck}`);
      // setCurrentTruck(truckDropdownData[0]?.truck);
    }
  }, [truckDropdownData, locationsDropdownData]);

  useEffect(() => {
    if (locationsDropdownData.length) {
      setLocationFilter(`location=${locationsDropdownData[0]}`);
    }
  }, [locationsDropdownData]);

  // console.log(truckDropdownData)
  const startYear = 2021;
  const endYear = new Date().getFullYear();

  const filterByDate = (start, end) => {
    const filter =
      start &&
      end &&
      `period[start]=${formatDate(start)["yyyy-mm-dd"]}&period[end]=${
        formatDate(end)["yyyy-mm-dd"]
      } 
   `;
    setDateFilter(filter);
    setDateRange([start, end]);
    setStartDate(formatDate(start)["yyyy-mm-dd"]);
    setEndDate(formatDate(end)["yyyy-mm-dd"]);
  };

  useFilterGraph({
    truckFilter,
    locationFilter,
    dateFilter,
    getData: getMonthlyAvaliablity,
  });

  // useEffect(() => {
  //   getMonthlyAvaliablity(
  //     "?period[start]=2022-03-01&truck=BIS/NB/002&period[end]=2022-04-01"
  //   );
  // }, []);

  const modalRef = useRef(null);
  const [action, setAction] = useState("");

  const closeModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "none";
  };

  const showModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "grid";
  };

  return (
    <DashboardLayout>
      {/* AVAILABILITY MODAL */}

      <div
        ref={modalRef}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "none",
          placeItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9000,
        }}
      >
        <div
          style={{
            width: 450,
            height: 450,
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: "3rem",
          }}
        >
          <p
            style={{
              fontSize: "2.5rem",
              color: "#000",
              marginBottom: "1.5rem",
              fontWeight: "bolder",
            }}
          >
            {action === "exporting" ? "Send" : "Download"} report
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            From: {dateFilter.split("&")[0].replace("period[start]=", "")}
          </p>

          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            To: {dateFilter.split("&")[1].replace("period[end]=", "")}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            For{" "}
            {truckFilter
              ? truckFilter.replace("truck=", "").split(",").length > 1
                ? `trucks: ${truckFilter
                    .replace("truck=", "")
                    .split(",")
                    .join(", ")}`
                : `truck: ${truckFilter.replace("truck=", "")}`
              : "all trucks"}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            At{" "}
            {locationFilter
              ? `${
                  locationFilter.replace("location=", "").split(",").length > 1
                    ? `locations: ${locationFilter
                        .replace("location=", "")
                        .split(",")
                        .join(", ")}`
                    : `location: ${locationFilter.replace("location=", "")}`
                }`
              : "all locations."}
          </p>
          <div className="action" style={{ marginTop: "2rem" }}>
            {action === "exporting" ? (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const user = MapTokenToUser();

                  const data = {
                    export: {
                      entity: "monthly_availability",
                      query: {
                        period: {
                          start: startDate,
                          end: endDate,
                        },
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "email",
                      recipients: [user.user.email],
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Send
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const data = {
                    export: {
                      entity: "monthly_availability",
                      query: {
                        period: {
                          start: startDate,
                          end: endDate,
                        },
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "download",
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Continue to download
              </button>
            )}
            <button
              style={{
                backgroundColor: "red",
                padding: "0.8rem 1.7rem",
                color: "#fff",
                fontFamily: "inherit",
                borderRadius: "0.4rem",
                minWidth: 120,
                fontSize: "1.4rem",
              }}
              onClick={(e) => closeModal(e)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      {/* AVAILABILITY MODAL */}
      <StyledDashboardContentWrapper>
        <PageHeaderLayout>
          <StyledDivFlex
            gap="0.8rem"
            flexDirectionSd="column"
            widthSd="100%"
            paddingS="2rem"
          >
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("exporting");
              }}
            >
              {isExporting ? "Sending......" : " Send Report to my Email"}
            </StyledPageHeaderButton>
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("downloading");
              }}
            >
              {" "}
              {isDownloading ? "Downloading" : "Download Report"}
            </StyledPageHeaderButton>
          </StyledDivFlex>
        </PageHeaderLayout>
        <StyledDivFlex
          // background={Theme.colors.neutralColor}
          padding="0.8rem 6.4rem"
          // marginTop="1rem"
          justifyContent="flex-end"
          gap="1.6rem"
          alignItems="center"
          paddingM="1rem 0"
          gapM="1.5rem"
          justifyContentM="center"
          flexDirectionS="column"
        >
          <Dropdown
            // background={Theme.colors.secondaryColor}
            value={
              locationFilter?.includes("location=") &&
              locationFilter.replace("location=", "")
            }
            isLoading={isLoading}
            name="location"
            label="Location"
            onChange={(data) => {
              const { location } = data;
              console.log(location);
              setSelectedLocations([...selectedLocations, location]);
              // window.alert(JSON.stringify(location))
              const filter = location && `location=${location}`;
              setLocationFilter(filter);
              setLocationDownload(location);
              setSelectedLocations([...selectedLocations, location]);

              // window.alert(JSON.stringify(selectedLocations))
            }}
            data={locationsDropdownData}
            gap="2rem"
            minWidth="8%"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            // multiSelect={true}
          />

          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            value={
              truckFilter?.includes("truck=") &&
              truckFilter.replace("truck=", "")
            }
            multiSelect={true}
            name="truck"
            label="Filter Truck"
            onChange={(data) => {
              // console.log("Data", data);
              const { truck } = data;
              const filter = truck && `truck=${truck}`;
              setCurrentTruck((prev) => (truck ? truck : ""));
              setTruckFilter(filter);
              setTruckDownload(truck);
            }}
            data={truckDropdownData.filter((x) => {
              if (
                selectedLocations.includes(x.city) ||
                selectedLocations.length == 0
              ) {
                return x;
              }
            })}
            gap="2rem"
            minWidth="16%"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            // multiSelect={true}
          />

          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            name="month"
            label="Months"
            onChange={(data) => {
              let { month } = data;
              if (!month) {
                setTrackMonth("");
                if (trackQuarter) {
                  const { start, end } = quarterLookUp[trackQuarter];
                  filterByDate(start, end);
                } else if (trackYear) {
                  const start = `${trackYear}-01-01`;
                  const end = `${trackYear}-12-31`;
                  filterByDate(start, end);
                } else {
                  month = months[new Date().getUTCMonth()];
                  setTrackMonth(month);
                  const { start, end } = monthLookUp[month];
                  filterByDate(start, end);
                }
              } else {
                setTrackMonth(month);
                const { start, end } = monthLookUp[month];
                filterByDate(start, end);
              }
            }}
            data={months}
            gap="2rem"
            minWidth="3%"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            // multiSelect={true}
          />

          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            name="quarter"
            label="Quarter"
            onChange={(data) => {
              const { quarter } = data;
              if (!quarter) {
                setTrackQuarter("");
                if (trackYear) {
                  const start = `${trackYear}-01-01`;
                  const end = `${trackYear}-12-31`;
                  filterByDate(start, end);
                } else if (trackMonth) {
                  const { start, end } = monthLookUp[trackMonth];
                  filterByDate(start, end);
                } else {
                  let month = months[new Date().getUTCMonth()];
                  setTrackMonth(month);
                  const { start, end } = monthLookUp[month];
                  filterByDate(start, end);
                }
              } else {
                setTrackQuarter(quarter);
                const { start, end } = quarterLookUp[quarter];
                filterByDate(start, end);
              }
            }}
            data={quarter}
            gap="2rem"
            minWidth="20%"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            // multiSelect={true}
          />

          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            name="year"
            label="Year"
            onChange={(data) => {
              const { year } = data;
              if (!year) {
                setTrackYear("");
                if (trackMonth) {
                  const { start, end } = monthLookUp[trackMonth];
                  filterByDate(start, end);
                } else if (trackQuarter) {
                  const { start, end } = quarterLookUp[trackQuarter];
                  filterByDate(start, end);
                } else {
                  let month = months[new Date().getUTCMonth()];
                  setTrackMonth(month);
                  const { start, end } = monthLookUp[month];
                  filterByDate(start, end);
                }
              } else {
                setTrackYear(year);
                const start = `${year}-01-01`;
                const end = `${year}-12-31`;
                filterByDate(start, end);
              }
            }}
            data={getYears(startYear, endYear)}
            gap="2rem"
            minWidth="5%"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            // multiSelect={true}
          />

          <StyledButton
            disabled={isLoading}
            background={Theme.colors.primaryColor}
            color={Theme.colors.secondaryColor}
            padding="1rem"
            borderRadius="1rem"
            widthS="90%"
            minWidth="5%"
            onClick={() => {
              setLocationFilter(null);
              setCurrentTruck("");
              setTruckFilter(null);
              const start = `${startYear}-01-01`;
              const end = `${endYear}-12-31`;
              filterByDate(start, end);
            }}
          >
            All Time
          </StyledButton>
        </StyledDivFlex>

        <StyledBox background={Theme.colors.neutralColor}>
          {/* <SpinningLoader /> */}
          <SubHeaderLayout
            current={currentTruck}
            text="Availability for the period:"
            dateRange={dateRange}
            count={data?.length}
          />
          <StyledDivGrid
            width="80%"
            gap="4rem"
            gapSd="2rem"
            padding="0.8rem 6.4rem"
            paddingSd="1rem !important"
          >
            {isLoading &&
              Array.from(Array(3).keys()).map((item, index) => (
                <Skeleton
                  style={{
                    height: "12rem",
                    width: "80%",
                    marginTop: "4rem",
                  }}
                  key={index.toString()}
                />
              ))}
          </StyledDivGrid>
          <>
            {data && !isLoading && (
              <StyledDivFlex
                padding="1rem 0rem 1rem 8rem"
                marginTop="-2rem"
                gap="3.2rem"
                justifyContentS="space-between"
                paddingS="1rem 3rem 1rem 1rem"
                flexDirectionSd="column"
                paddingSd="2rem"
                gapS="2rem"
              >
                <DailyRating
                  label="Uptime"
                  count={`${
                    summary?.total_uptime_hours
                      ? summary?.total_uptime_hours.toLocaleString()
                      : 0
                  } Hours`}
                  // count={`${
                  //   summary?.total_days
                  //     ? summary?.total_days.toLocaleString()
                  //     : 0
                  // } days   (${
                  //   summary?.total_uptime_hours
                  //     ? summary?.total_uptime_hours.toLocaleString()
                  //     : 0
                  // } Hours)`}
                />
                <DailyRating
                  label=" Availability"
                  count={`${
                    summary?.avg_availability
                      ? summary?.avg_availability?.toFixed()
                      : 0
                  }%`}
                />
              </StyledDivFlex>
            )}

            {/* BARCHART STARTS FROM HERE  */}
            <AvaliablityGraph data={data} isLoading={isLoading} />
          </>
          {/* BARCHART ENDS HERE */}
        </StyledBox>
      </StyledDashboardContentWrapper>
    </DashboardLayout>
  );
};

export default MonthlyAvaliablity;
