export const breakPoint = {
    VeryLargeScreen: "2560px",

    largeScreen: "1250px",

    mediumScreen: "970px",

    smallScreen: "650px",

    smallestScreen: "441px",
};