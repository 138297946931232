import React, { useContext, useState, useRef, useMemo } from "react";
import MapTokenToUser from "../../../Authorization/MapTokenToUser";
import { StyledDashboardContentWrapper } from "../../../components/common/Basics/DashboardContentWrapper";
import { StyledBox } from "../../../components/common/Basics/DivBox";
import { StyledDivFlex } from "../../../components/common/Basics/DivFlex";
import { StyledPageHeaderButton } from "../../../components/common/Basics/PageHeaderButton";
import PickDate from "../../../components/common/DatePicker";
import Dropdown from "../../../components/common/Dropdown";
import Paginations from "../../../components/common/Paginations";
import SpinnerWithText from "../../../components/common/SpinnerWithText";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import PageHeaderLayout from "../../../components/Layouts/HeaderLayout";
import SubHeaderLayout from "../../../components/Layouts/SubHeaderLayout";
import { dropdownFilterContext } from "../../../Context/DropdownFiltersContext";
import { useGetCSVExport } from "../../../hooks/useGetCSVExport";
import { useFilterGraph } from "../../../hooks/useGraphFilter";
import { Theme } from "../../../Theme";
import { formatDate } from "../../../utils/FormatDate";
import { getPreviousDate, getTodayDate } from "../../../utils/GetDate";
import { useGetTruckManagement } from "./hooks/useGetTruckManagement";
import TruckManagementTable from "./TruckTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// DEBUG
// import { dummyTruckData } from "../../../DUMMYDATACHART";

const TruckManagment = () => {
  const { truckDropdownData, locationsDropdownData } = useContext(
    dropdownFilterContext
  );

  const [activePage, setActivePage] = useState(0);

  const resetPagination = () => {
    const pageFilter = `page=1`;
    setPageFilter(pageFilter);
    setActivePage(0);
  };

  const { data, isLoading, getTruckManagement, error, totalPages } =
    useGetTruckManagement();

  const [selectedLocations, setSelectedLocations] = useState([]);

  const startDate = getPreviousDate(31);
  const endDate = getTodayDate();

  const [truckDownload, setTruckDownload] = useState();
  const [locationDownload, setLocationDownload] = useState();

  const [pageFilter, setPageFilter] = useState();

  const filter = `period[start]=${startDate}&period[end]=${endDate}`;
  const [locationFilter, setLocationFilter] = useState();
  const [truckfilter, setTruckFilter] = useState();
  const [dateFilter, setDateFilter] = useState(filter);
  const [dateRange, setDateRange] = useState([startDate, endDate]);

  const { getCSVExport, csvData, isExporting, isDownloading } =
    useGetCSVExport();

  useFilterGraph({
    truckfilter,
    locationFilter,
    pageFilter,

    getData: getTruckManagement,
  });

  const modalRef = useRef(null);
  const [action, setAction] = useState("");

  const closeModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "none";
  };

  const showModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "grid";
  };

  const detailKeys = useMemo(
    () => [
      "Truck",
      "Truck type",
      "Truck chassis no",
      "City",
      "Access control",
      "Assigned drivers",
      "Commissioning",
      "Country",
      "Customer",
      "Drive type",
      "Load capacity",
      "Maintenance appointments",
      "Next maintenance",
      "Security-Code",
      "Series",
      "Shift models",
      "Shock threshold",
      "Speed",
      "Synchronisation",
      "Technology",
    ],
    []
  );

  const [detailsData, setDetailsData] = useState(null);

  const detailsModalRef = useRef(null);

  const closeDetailsModal = (e) => {
    e.preventDefault();
    detailsModalRef.current.style.display = "none";
  };

  const showDetailsModal = (e, data) => {
    e.preventDefault();
    console.log("data", data);
    setDetailsData(data);
    detailsModalRef.current.style.display = "grid";
  };

  return (
    <DashboardLayout>
      <div
        ref={modalRef}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "none",
          placeItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9000,
        }}
      >
        <div
          style={{
            width: 450,
            height: 450,
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: "3rem",
          }}
        >
          <p
            style={{
              fontSize: "2.5rem",
              color: "#000",
              marginBottom: "1.5rem",
              fontWeight: "bolder",
            }}
          >
            {action === "exporting" ? "Send" : "Download"} report
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            From: {dateFilter.split("&")[0].replace("period[start]=", "")}
          </p>

          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            To: {dateFilter.split("&")[1].replace("period[end]=", "")}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            For all trucks
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            At{" "}
            {locationFilter
              ? `${
                  locationFilter.replace("location=", "").split(",").length > 1
                    ? `locations: ${locationFilter
                        .replace("location=", "")
                        .split(",")
                        .join(", ")}`
                    : `location: ${locationFilter.replace("location=", "")}`
                }`
              : "all locations."}
          </p>
          <div className="action" style={{ marginTop: "2rem" }}>
            {action === "exporting" ? (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const user = MapTokenToUser();
                  const data = {
                    export: {
                      entity: "truck_management",
                      query: {
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "email",
                      recipients: [user.user.email],
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Send
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const data = {
                    export: {
                      entity: "truck_management",
                      query: {
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "download",
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Continue to download
              </button>
            )}
            <button
              style={{
                backgroundColor: "red",
                padding: "0.8rem 1.7rem",
                color: "#fff",
                fontFamily: "inherit",
                borderRadius: "0.4rem",
                minWidth: 120,
                fontSize: "1.4rem",
              }}
              onClick={(e) => closeModal(e)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <StyledDashboardContentWrapper>
        <PageHeaderLayout>
          <StyledDivFlex
            gap="1rem"
            flexDirectionSd="column"
            widthSd="100%"
            paddingS="2rem"
          >
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("exporting");
              }}
            >
              {isExporting ? "Sending......" : " Send Report to my Email"}
            </StyledPageHeaderButton>
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("downloading");
              }}
            >
              {isDownloading ? "Downloading" : "Download Report"}
            </StyledPageHeaderButton>
          </StyledDivFlex>
        </PageHeaderLayout>
        <StyledDivFlex
          // background={Theme.colors.neutralColor}
          padding="1rem 8rem"
          // marginTop="2rem"
          justifyContent="flex-end"
          gap="4rem"
          alignItems="center"
          paddingM="1rem 0"
          gapM="1.5rem"
          justifyContentM="center"
          flexDirectionS="column"
        >
          <Dropdown
            // background={Theme.colors.secondaryColor}
            value={
              locationFilter?.includes("location=") &&
              locationFilter.replace("location=", "")
            }
            isLoading={isLoading}
            name="location"
            label="Location"
            onChange={(data) => {
              const { location } = data;
              const filter = location ? `location=${location}` : null;
              resetPagination();
              setLocationFilter(filter);
              resetPagination();
              setLocationDownload(location);
              setSelectedLocations([...selectedLocations, location]);
            }}
            data={locationsDropdownData}
            gap="2rem"
            minWidth="22rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            multiSelect={true}
          />
          {/* <PickDate
            onChange={(date) => {
              const filter =
                date &&
                `data.Created on:between:${
                  formatDate(date[0])["yyyy-mm-dd"]
                }, ${formatDate(date[1])["yyyy-mm-dd"]}`;
              setDateRange(date);
              setDateFilter(filter);
              resetPagination();
            }}
          /> */}
          {/*REMOVES THE TRUCK DROPDOWN FILTER, MIGHT ROLE BACK LATER */}
          {/* <Dropdown
            // background={Theme.colors.secondaryColor}
            name="truck"
            label="Truck"
            onChange={(data) => {
              const { truck } = data;
              const filter = truck ? `truck=${truck}` : "";
              setTruckFilter(filter);
              resetPagination();
              setTruckDownload(truck);
            }}
            minWidth="20rem"
            data={truckDropdownData.filter((x)=>{if(selectedLocations.includes(x.city) || selectedLocations.length==0){return x}})}
            gap="2rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            multiSelect={true}
          /> */}
        </StyledDivFlex>
        <StyledBox background={Theme.colors.neutralColor}>
          {/* <SpinningLoader /> */}
          <SubHeaderLayout
            text="Truck  Management:"
            dateRange={dateRange}
            count={data?.length}
            data={data}
          />
        </StyledBox>

        <StyledBox>
          {isLoading ? (
            <SpinnerWithText isLoading={isLoading} margin="1rem 0 0 0" />
          ) : (
            <>
              <TruckManagementTable
                data={data}
                showDetailsModal={showDetailsModal}
              />
            </>
          )}

          <Paginations
            getData={getTruckManagement}
            totalPages={totalPages}
            isLoading={isLoading}
            data={data}
            onPageSelected={setPageFilter}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </StyledBox>
      </StyledDashboardContentWrapper>
      <div
        ref={detailsModalRef}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "none",
          placeItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9000,
        }}
        onClick={(e) => {
          closeDetailsModal(e);
          setDetailsData(null);
        }}
      >
        <div
          style={{
            width: 450,
            // height: 450,
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: "3rem",
          }}
        >
          {console.log(detailsData)}
          {detailsData &&
            (() => {
              let fields = detailKeys.map((key) => (
                <p>
                  <b>{key === "City" ? "Location" : key}</b> :{" "}
                  {detailsData[key]}
                </p>
              ));
              return fields;
            })()}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TruckManagment;
