import React, { useState, useEffect, useContext, useRef } from "react";

import { StyledDashboardContentWrapper } from "../../../components/common/Basics/DashboardContentWrapper";
import { StyledDivFlex } from "../../../components/common/Basics/DivFlex";
import { StyledPageHeaderButton } from "../../../components/common/Basics/PageHeaderButton";
import Dropdown from "../../../components/common/Dropdown";

import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import PageHeaderLayout from "../../../components/Layouts/HeaderLayout";
import { locations, period, trucks } from "../../../DUMMYDATA";
import { Theme } from "../../../Theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SubHeaderLayout from "../../../components/Layouts/SubHeaderLayout";
import { StyledBox } from "../../../components/common/Basics/DivBox";
import { StyledButton } from "../../../components/common/Button/style";
import { dummyShockingData } from "../../../DUMMYDATACHART.js";
import ShockSensingTable from "./ShockSensingTable";
import { useGetShockingSensingTable } from "./hooks/useGetShockSensingTable";
import Paginations from "../../../components/common/Paginations";
import { useGetShockingSensingBarChart } from "./hooks/useGetShockingSensingBarChart";
import ShockSensingBar from "./ShockSensingBar";
import { dropdownFilterContext } from "../../../Context/DropdownFiltersContext";
import SpinnerWithText from "../../../components/common/SpinnerWithText";
import { formatDate } from "../../../utils/FormatDate";
import PickDate from "../../../components/common/DatePicker";
import { useFilter } from "../../../hooks/useFilter";
import { useGetCSVExport } from "../../../hooks/useGetCSVExport";
import MapTokenToUser from "../../../Authorization/MapTokenToUser";
import { useFilterGraph } from "../../../hooks/useGraphFilter";
import { getPreviousDate, getTodayDate } from "../../../utils/GetDate";
import zIndex from "@mui/material/styles/zIndex";

const ShockingSense = () => {
  const [activeButton, setActiveButton] = useState("Table");
  const buttons = ["Table", "Graph"];
  const { getShockingSensingTable, totalPages, isLoading, error, data } =
    useGetShockingSensingTable();
  const { getShockingSensingBarChart, graphdata } =
    useGetShockingSensingBarChart();
  const { truckDropdownData, locationsDropdownData } = useContext(
    dropdownFilterContext
  );
  // DEBUG
  // console.log("Truck DropDown Data", truckDropdownData);
  // console.log("Location Dropdown Data", locationsDropdownData);
  const { getCSVExport, csvData, isDownloading, isExporting } =
    useGetCSVExport();

  const [activePage, setActivePage] = useState(0);

  const resetPagination = () => {
    const pageFilter = `page=1`;
    setPageFilter(pageFilter);
    setActivePage(0);
  };

  const [selectedLocations, setSelectedLocations] = useState([]);

  const [startDate, setStartDate] = useState(getPreviousDate(31));
  const [endDate, setEndDate] = useState(getTodayDate());
  const [truckDownload, setTruckDownload] = useState();
  const [locationDownload, setLocationDownload] = useState();

  const filter = `period[start]=${startDate}&period[end]=${endDate}`;

  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [locationFilter, setLocationFilter] = useState();
  const [truckFilter, setTruckFilter] = useState();
  const [currentTruck, setCurrentTruck] = useState("");
  const [dateFilter, setDateFilter] = useState(filter);
  const [pageFilter, setPageFilter] = useState();

  useFilterGraph({
    truckFilter,
    locationFilter,
    dateFilter,
    pageFilter,

    // activeButton === "Table"
    //   ? getShockingSensingTable
    //   : getShockingSensingBarChart
    getData: getShockingSensingTable,
  });

  // console.log("checking shocking graph data", graphdata);

  // useEffect(() => {
  //   getShockingSensingTable();
  // }, []);

  useEffect(() => {
    getShockingSensingBarChart();
  }, []);

  const modalRef = useRef(null);
  const [action, setAction] = useState("");

  const closeModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "none";
  };

  const showModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "grid";
  };

  return (
    <DashboardLayout>
      <div
        ref={modalRef}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "none",
          placeItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9000,
        }}
      >
        <div
          style={{
            width: 450,
            height: 450,
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: "3rem",
          }}
        >
          <p
            style={{
              fontSize: "2.5rem",
              color: "#000",
              marginBottom: "1.5rem",
              fontWeight: "bolder",
            }}
          >
            {action === "exporting" ? "Send" : "Download"} report
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            From: {dateFilter.split("&")[0].replace("period[start]=", "")}
          </p>

          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            To: {dateFilter.split("&")[1].replace("period[end]=", "")}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            For{" "}
            {truckFilter
              ? truckFilter.replace("truck=", "").split(",").length > 1
                ? `trucks: ${truckFilter
                    .replace("truck=", "")
                    .split(",")
                    .join(", ")}`
                : `truck: ${truckFilter.replace("truck=", "")}`
              : "all trucks"}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            At{" "}
            {locationFilter
              ? `${
                  locationFilter.replace("location=", "").split(",").length > 1
                    ? `locations: ${locationFilter
                        .replace("location=", "")
                        .split(",")
                        .join(", ")}`
                    : `location: ${locationFilter.replace("location=", "")}`
                }`
              : "all locations."}
          </p>
          <div className="action" style={{ marginTop: "2rem" }}>
            {action === "exporting" ? (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const user = MapTokenToUser();
                  const data = {
                    export: {
                      entity: "shock_sensing",
                      query: {
                        period: {
                          start: startDate,
                          end: endDate,
                        },
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "email",
                      recipients: [user.user.email],
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Send
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const data = {
                    export: {
                      entity: "shock_sensing",
                      query: {
                        period: {
                          start: startDate,
                          end: endDate,
                        },
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "download",
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Continue to download
              </button>
            )}
            <button
              style={{
                backgroundColor: "red",
                padding: "0.8rem 1.7rem",
                color: "#fff",
                fontFamily: "inherit",
                borderRadius: "0.4rem",
                minWidth: 120,
                fontSize: "1.4rem",
              }}
              onClick={(e) => closeModal(e)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <StyledDashboardContentWrapper overFlowXS="hidden" overFlowYS="auto">
        <PageHeaderLayout>
          <StyledDivFlex
            gap="1rem"
            flexDirectionSd="column"
            widthSd="100%"
            paddingS="2rem"
          >
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("exporting");
              }}
            >
              {isExporting ? "Sending..." : " Send Report to my Email"}
            </StyledPageHeaderButton>
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("downloading");
              }}
            >
              {isDownloading ? "Downloading..." : "Download Report"}
            </StyledPageHeaderButton>
          </StyledDivFlex>
        </PageHeaderLayout>

        <StyledDivFlex
          // background={Theme.colors.neutralColor}
          // padding="1rem 2rem"
          padding="1rem 8rem"
          marginTop="2rem"
          // justifyContent="space-between"
          justifyContent="flex-end"
          gap="0.5rem"
          // gap="4rem"
          alignItems="center"
          paddingM="1rem 0"
          gapM="1.5rem"
          justifyContentM="center"
          flexDirectionS="column"
        >
          <Dropdown
            // background={Theme.colors.secondaryColor}
            value={
              locationFilter?.includes("location=") &&
              locationFilter.replace("location=", "")
            }
            isLoading={isLoading}
            name="location"
            label="Location"
            onChange={(data) => {
              const { location } = data;
              const filter = location ? `location=${location}` : "";
              setLocationFilter(filter);
              resetPagination();
              setLocationDownload(location);
              setSelectedLocations([...selectedLocations, location]);

              // getShockingSensingTable(filter);
            }}
            data={locationsDropdownData}
            gap="2rem"
            minWidth="20rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            multiSelect={true}
          />

          <Dropdown
            // background={Theme.colors.secondaryColor}
            value={
              truckFilter?.includes("truck=") &&
              truckFilter.replace("truck=", "")
            }
            isLoading={isLoading}
            name="truck"
            label="Filter Truck"
            onChange={(data) => {
              const { truck } = data;
              const filter = truck ? `truck=${truck}` : "";
              setCurrentTruck((prev) => (truck ? truck : ""));
              // getShockingSensingTable(filter);
              setTruckFilter(filter);
              resetPagination();
              setTruckDownload(truck);
            }}
            minWidth="20rem"
            data={truckDropdownData.filter((x) => {
              if (
                selectedLocations.includes(x.city) ||
                selectedLocations.length == 0
              ) {
                return x;
              }
            })}
            gap="2rem"
            multiSelect={true}
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            widthS="90%"
          />

          <PickDate
            onChange={(date) => {
              if (!date) {
                let endDate = new Date();
                let endDateMill = endDate.getTime();
                let interVal = 31 * 24 * 60 * 60 * 1000;
                let diff = endDateMill - interVal;
                let startDate = new Date(diff);
                date = [startDate, endDate];
              }
              const filter =
                date &&
                `period[start]=${
                  formatDate(date[0])["yyyy-mm-dd"]
                }&period[end]=${formatDate(date[1])["yyyy-mm-dd"]}`;
              setDateFilter(filter);
              setDateRange(date);
              resetPagination();
              setStartDate(formatDate(date[0])["yyyy-mm-dd"]);
              setEndDate(formatDate(date[1])["yyyy-mm-dd"]);
              // }
            }}
            widthS="90%"
            loading={isLoading}
          />
        </StyledDivFlex>
        <StyledBox background={Theme.colors.neutralColor}>
          {/* <SpinningLoader /> */}
          <SubHeaderLayout
            text="Shock Sensing for the period:"
            dateRange={dateRange}
            count={data?.length}
            data={data}
            current={currentTruck}
            buttons={
              <StyledDivFlex gap="2rem">
                {buttons.map((text, index) => (
                  <StyledButton
                    borderRadius="2.5rem"
                    fontSize="calc(0.5rem  +  0.8vw)"
                    fontSizeSd="1.2rem"
                    background={
                      text === activeButton
                        ? Theme.colors.primaryColor
                        : Theme.colors.neutralColor
                    }
                    color={
                      text === activeButton
                        ? Theme.colors.neutralColor
                        : Theme.colors.primaryColor
                    }
                    border={
                      text === activeButton
                        ? "none"
                        : `1px solid ${Theme.colors.primaryColor}`
                    }
                    fontWeight="500"
                    padding="1rem 3.2rem 1rem 2.1vw"
                    paddingSd="1rem 2rem !important"
                    // color={Theme.colors.neutralColor}
                    onClick={() => setActiveButton(text)}
                    key={index.toString()}
                  >
                    {text}
                  </StyledButton>
                ))}
              </StyledDivFlex>
            }
          />

          {activeButton === "Table" ? (
            <>
              {isLoading ? (
                <SpinnerWithText
                  isLoading={isLoading}
                  margin="1rem 0 0 0"
                  spinnerText="Kindly wait. This can take up to 2mins..."
                />
              ) : (
                <ShockSensingTable data={data} />
              )}

              {data?.length >= 1 && (
                <Paginations
                  getData={getShockingSensingTable}
                  totalPages={totalPages}
                  isLoading={isLoading}
                  data={data}
                  onPageSelected={setPageFilter}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              )}
            </>
          ) : (
            <>
              {isLoading ? (
                <SpinnerWithText
                  isLoading={isLoading}
                  margin="1rem 0 0 0"
                  spinnerText="Kindly wait. This can take up to 2mins..."
                />
              ) : (
                <ShockSensingBar data={data} current={currentTruck} />
              )}

              {data?.length >= 1 && (
                <Paginations
                  getData={getShockingSensingTable}
                  totalPages={totalPages}
                  isLoading={isLoading}
                  data={data}
                  onPageSelected={setPageFilter}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              )}
            </>
          )}

          {/* BARCHART STARTS FROM HERE  */}

          {/* BARCHART ENDS HERE */}
        </StyledBox>
      </StyledDashboardContentWrapper>
    </DashboardLayout>
  );
};

export default ShockingSense;
