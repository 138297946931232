import React, { useEffect, useState } from "react";

import {
  LineChart,
  BarChart,
  Bar,
  Label,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { StyledBox } from "../../../../components/common/Basics/DivBox";
import { StyledTextHeading } from "../../../../components/common/Basics/Heading";
import { Theme } from "../../../../Theme";
import { formatDate } from "../../../../utils/FormatDate";
import moment from "moment";

const BatteryGraph = ({ data, isLoading, current }) => {
  console.log("data", data);
  const [graphData, setGraphData] = useState();
  // console.log("graphData", graphData)

  useEffect(() => {
    if (data) {
      const newData = data.map((data) => {
        // data.at_time = formatDate(data?.daytime)["hh:mm"];
        data.at_time = moment(data.at_time, "hh:mm").format("hh:mm A");
        return data;
      });

      setGraphData(newData);
    }
  }, [data]);
  // const day = new Date();
  // const result = formatDate("2021-11-17T07:34:26.000Z")["hh:mm"];

  // // const result = day.getHours() + ":" + day.getMinutes();
  return (
    <StyledBox
      marginTop="0rem"
      padding="1rem 7.0rem"
      height="62vh"
      style={{ maxWidth: "100vw", overflowX: " scroll " }}
    >
      {/* <ResponsiveContainer width="100%" height="100%"> */}
      {data?.length && !isLoading ? (
        <BarChart
          // width={10}
          // height={500}
          width={1000}
          height={400}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="at_time"
            stroke="#000000"
            tick={{ fontSize: 10 }}
            domain={["auto", "auto"]}
            angle={-45}
            textAnchor="end"
            height={60}
            // allowDuplicatedCategory={false}
          >
            <Label
              value={`Time (${current})`}
              offset={-5}
              position="insideBottom"
            />
          </XAxis>
          <YAxis
            label={{
              value: "Intensity (%)",
              angle: -90,
              position: "insideLeft",
            }}
            stroke="#000000"
            domain={[0, "dataMax + 0"]}
            tickCount={8}
          />
          <Tooltip cursor={{ fill: "transparent" }} />
          {/* <Legend /> */}

          <Bar dataKey="value" fill="#E8743B" />
        </BarChart>
      ) : (
        !isLoading && (
          <StyledTextHeading
            color="grey"
            textAlign="center"
            fontSize="1.6rem"
            paddingTop="0.8rem"
          >
            No Result found
          </StyledTextHeading>
        )
      )}

      {/* </ResponsiveContainer> */}
    </StyledBox>
  );
};

export default BatteryGraph;
