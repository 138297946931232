import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../common/Dropdown";
// import { companies } from "../../DUMMYDATA";
// import { StyledBox } from "../common/Basics/DivBox";
// import { StyledText } from "../common/Basics/StyledText";
import { useGetAllCompanies } from "./hooks/useGetAllCompanies";
import { useSwitchCompany } from "./hooks/useSwitchCompany";
import jsCookie from "js-cookie";
import { StyledDivFlex } from "../common/Basics/DivFlex";
import { useLoginClient } from "../../pages/Login/hooks/useClientLogin";
import { toast } from "react-toastify";

const Companies = ({ onChange }) => {
  const [getCompanies, setGetCompanies] = useState([]);
  const { getAllCompany, isLoading, error, data } = useGetAllCompanies();
  const { error: loginError, data: loginData, loginClient } = useLoginClient();
  const {
    switchCompany,
    data: switchCompanyData,
    error: switchCompanyError,
    isLoading: switchCompanyIsLoading,
  } = useSwitchCompany();

  useEffect(() => {
    getAllCompany();
  }, []);

  useEffect(() => {
    if (data) {
      const newData = data.map((data) => {
        return data?.name;
      });
      setGetCompanies(newData);
    }
  }, [data]);

  const navigate = useNavigate();

  const handleLogOut = (event) => {
    event?.preventDefault();
    jsCookie.remove("userToken");
    navigate("/");
    window.location.reload();
  };

  const backgroundLogin = () => {
    jsCookie.remove("userToken");
    let loginData = localStorage.getItem("login_credentials");
    if (loginData) loginData = JSON.parse(loginData);
    loginClient(loginData);
  };

  const handleSwitchCompany = async (company_name) => {
    await toast.promise(switchCompany(company_name, backgroundLogin), {
      pending: "Switching Client",
      success: {
        render({ data }) {
          return data;
        },
      },
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
  };

  return (
    <StyledDivFlex widthS="100%">
      <Dropdown
        data={getCompanies}
        name="company_name"
        label="Clients"
        showDropdown={true}
        onChange={(item) => {
          const { company_name } = item;
          const data = { company_name };
          // switchCompany(data, backgroundLogin);
          handleSwitchCompany(data);
        }}
        widthS="100%"
        gapS="2rem"
        minWidth="70%"
      />
    </StyledDivFlex>
  );
};

export default Companies;
