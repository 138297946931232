// import React, { useContext } from "react";

export const sideBarData = [
  {
    id: 1,
    title: "General Dashboard",
    link: "/home",
  },

  {
    id: 2,
    title: "Availability ",
    link: "/monthly-avaliablity",
  },

  // {
  //     id: 3,
  //     title: "Driver Management ",
  //     link: "/driver-management"
  // },

  {
    id: 10,
    title: "Driver Management",
    link: "/driver",
  },

  // {
  //     id: 4,
  //     title: "Truck Usage",
  //     link: "/truck-usage",
  // },

  {
    id: 5,
    title: "Occupancy Journal",
    link: "/occupancy-journal",
  },

  {
    id: 6,
    title: "Battery ",
    link: "/battery",
  },

  {
    id: 7,
    title: "Co2 Reduction",
    link: "/co2-reduction",
  },

  {
    id: 8,
    title: "Maintenance ",
    link: "/maintenance",
  },

  {
    id: 9,
    title: "Shocks Sensing ",
    link: "/shocking-sense",
  },

  {
    id: 10,
    title: "Truck Management ",
    link: "/truck-management",
  },
];

export const adminNavbarData = [
  {
    id: 1,
    title: "Client Management ",
    link: "/client-management",
  },

  {
    id: 2,
    title: "Admin Management ",
    link: "/user-management",
  },

  // {
  //     id: 3,
  //     title: "Upload Data ",
  //     link: "/upload-data",
  // },

  {
    id: 4,
    title: "Battery Upload ",
    link: "/battery-upload",
  },

  {
    id: 5,
    title: "Availability Upload ",
    link: "/availability-upload",
  },
];
