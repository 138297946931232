import React, { useEffect, useState } from "react";
import {
  // BarChart,
  // Bar,
  // LineChart,
  // Line,
  // Label,
  // Cell,
  // XAxis,
  // LabelList,
  // YAxis,
  // CartesianGrid,
  // Tooltip,
  // Legend,
  // Brush,
  ResponsiveContainer,
} from "recharts";
import { StyledBox } from "../../../../components/common/Basics/DivBox";
import { StyledTextHeading } from "../../../../components/common/Basics/Heading";
import { formatDate } from "../../../../utils/FormatDate";

/**
 *
 * @description NEW CHART IMPLEMENTATION
 *
 */

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "AVAILABILITY CHART",
    },
  },
};

/**
 *
 * @description NEW CHART IMPLEMENTATION
 *
 */

const AvaliablityGraph = ({ data, isLoading }) => {
  const [extractDay, setExtractDay] = useState();
  const [graphData, setGraphData] = useState([]);

  const getTruckName = () => {
    // let currentData = data.find(d => d.)
  };

  useEffect(() => {
    if (data) {
      // console.log(data);
      let graphData = {};
      let labels = [];
      let datasets = [];
      const newData = data.map((data) => {
        // const day = new Date(data.day);
        // data.getDay = `${day.getDate()}/${day.getMonth()}`;
        // console.log(data);
        // data.getDay = formatDate(data.day)["dd/month"];
        data.availability = data.data.Availability;
        data.getDay = formatDate(data.extracted_at)["dd/month"];
        let isLabelPresent = labels.find((label) => label === data.getDay);
        if (!isLabelPresent) {
          labels.push(data.getDay);
        }
        let indexOfCurrentLabel = labels.indexOf(data.getDay);
        let isPresent = datasets.find((item) => item.label === data.data.Truck);
        if (!isPresent) {
          let newData = {
            label: data.data.Truck,
            data: [],
            backgroundColor: `hsl(${Math.floor(
              Math.random() * 361
            )}, 70%, 50%)`,
          };
          newData.data[indexOfCurrentLabel] = {
            [data.getDay]: data.availability,
          };
          datasets.push(newData);
        } else {
          let theIndex = datasets.indexOf(isPresent);
          isPresent.data[indexOfCurrentLabel] = {
            [data.getDay]: data.availability,
          };
          datasets[theIndex] = isPresent;
        }
        return data;
      });
      labels.forEach((label, index) => {
        datasets.forEach((dataset) => {
          if (!dataset?.data[index]) {
            dataset.data[index] = 0;
          } else {
            dataset.data[index] = dataset.data[index][label];
          }
        });
      });

      graphData = { labels, datasets };
      // console.log(graphData);
      setGraphData(graphData);
      setExtractDay(newData);
    }
  }, [data]);

  return (
    <StyledBox
      marginTop="0rem"
      padding="1rem 6.5rem"
      height="44vh"
      // background="red"
      // width="50vw"
      style={{ maxWidth: "100vw", overflowX: " scroll " }}
    >
      {data?.length && !isLoading ? (
        <ResponsiveContainer width="100%">
          {/* <LineChart
            width={2000}
            height={400}
            data={extractDay}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="getDay"
              stroke="#000000"
              angle={-45}
              tick={{ fontSize: 10 }}
              height={60}
              allowDuplicatedCategory={false}
              dy={10}
            >
              <Label value="Days" position="insideBottom" />
              //{" "}
            </XAxis>
            <YAxis
              label={{
                value: "Uptime (%)",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="availability" stroke="#E8743B" />
          </LineChart> */}
          <Line options={options} data={graphData} />
        </ResponsiveContainer>
      ) : (
        !isLoading && (
          <StyledTextHeading
            color="grey"
            textAlign="center"
            fontSize="2rem"
            paddingTop="1rem"
          >
            No Result found
          </StyledTextHeading>
        )
      )}
    </StyledBox>
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let truck = payload[0].payload.truck;
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        <p
          className="label"
          style={{ color: "orangered" }}
        >{`hours: ${payload[0].value}`}</p>
        <p className="desc" style={{ color: "blue" }}>
          {truck}
        </p>
      </div>
    );
  }

  return null;
};

export default AvaliablityGraph;
