import React from "react";
import "./App.css";
import {
  HashRouter,
  Routes,
  Route,
  // Redire,
  // useNavigate,
} from "react-router-dom";
import GlobalProvider from "./Context/GlobalContext";
import DropdownFilterProvider from "./Context/DropdownFiltersContext";
// import jwt_decode from "jwt-decode";
import { ToastContainer } from "react-toastify";

import { publicRoutes, protectedRoutes } from "./Routes/Routes";
// import { Login } from "@mui/icons-material";

import { PublicRouteGaurd, AdminGuard, ClientGuard } from "./Routes/Gaurd";

function App() {
  return (
    <GlobalProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <DropdownFilterProvider>
        <HashRouter>
          <Routes>
            {" "}
            {publicRoutes.map(({ path, component }, index) => (
              <Route
                path={path}
                element={
                  <PublicRouteGaurd key={index.toString()}>
                    {" "}
                    {component}{" "}
                  </PublicRouteGaurd>
                }
                key={index.toString()}
              />
            ))}{" "}
            {protectedRoutes.admin.map(({ path, component }, index) => (
              <Route
                path={path}
                element={
                  <AdminGuard key={index.toString()}> {component} </AdminGuard>
                }
                exact={true}
                key={index.toString()}
              />
            ))}
            {protectedRoutes.client.map(({ path, component }, index) => (
              <Route
                path={path}
                element={
                  <ClientGuard key={index.toString()}>
                    {" "}
                    {component}{" "}
                  </ClientGuard>
                }
                exact={true}
                key={index.toString()}
              />
            ))}
          </Routes>{" "}
        </HashRouter>{" "}
      </DropdownFilterProvider>{" "}
    </GlobalProvider>
  );
}

export default App;
