import React, { useEffect, useState, useContext, useRef } from "react";
import { StyledDashboardContentWrapper } from "../../../components/common/Basics/DashboardContentWrapper";
import { StyledDivFlex } from "../../../components/common/Basics/DivFlex";
import { StyledPageHeaderButton } from "../../../components/common/Basics/PageHeaderButton";
import Dropdown from "../../../components/common/Dropdown";
import PageHeadingButtons from "../../../components/common/PageButton";
import DashboardLayout from "../../../components/Layouts/DashboardLayout";
import PageHeaderLayout from "../../../components/Layouts/HeaderLayout";
import { Theme } from "../../../Theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { locations, period, trucks } from "../../../DUMMYDATA";
import { StyledBox } from "../../../components/common/Basics/DivBox";
import SubHeaderLayout from "../../../components/Layouts/SubHeaderLayout";
import { useOccupancyJournal } from "./hooks/useGetOccupancyJournal";
import JournalTable from "./JournalTable";
import Paginations from "../../../components/common/Paginations";
import { removeDuplicate } from "../../../components/common/RemoveDuplicate";
import { dropdownFilterContext } from "../../../Context/DropdownFiltersContext";
import SpinnerWithText from "../../../components/common/SpinnerWithText";
import { formatDate } from "../../../utils/FormatDate";
import { useFilter } from "../../../hooks/useFilter";
import PickDate from "../../../components/common/DatePicker";
import { useGetCSVExport } from "../../../hooks/useGetCSVExport";
import MapTokenToUser from "../../../Authorization/MapTokenToUser";
import { getPreviousDate, getTodayDate } from "../../../utils/GetDate";
import { useFilterGraph } from "../../../hooks/useGraphFilter";

const OccupancyJournal = () => {
  const [selectedLocations, setSelectedLocations] = useState([]);

  const { getOccupancyJournal, error, isLoading, data, totalPages } =
    useOccupancyJournal();
  const [pageFilter, setPageFilter] = useState();
  // const [locationData, setLocationData] = useState([]);
  // const [truckData, setTruckData] = useState([]);

  const [activePage, setActivePage] = useState(0);

  const resetPagination = () => {
    const pageFilter = `page=1`;
    setPageFilter(pageFilter);
    setActivePage(0);
  };

  const { truckDropdownData, locationsDropdownData } = useContext(
    dropdownFilterContext
  );

  const [startDate, setStartDate] = useState(getPreviousDate(31));
  const [endDate, setEndDate] = useState(getTodayDate());
  const [truckDownload, setTruckDownload] = useState();
  const [locationDownload, setLocationDownload] = useState();

  const filter = `period[start]=${startDate}&period[end]=${endDate}`;
  const [locationFilter, setLocationFilter] = useState();
  const [truckFilter, setTruckFilter] = useState();
  const [currentTruck, setCurrentTruck] = useState("");
  const [dateFilter, setDateFilter] = useState(filter);
  const [dateRange, setDateRange] = useState([startDate, endDate]);

  const {
    getCSVExport,
    csvData,
    isLoading: isLoadingDownload,
    isExporting,
  } = useGetCSVExport();

  useFilterGraph({
    truckFilter,
    locationFilter,
    dateFilter,
    pageFilter,
    getData: getOccupancyJournal,
  });

  // useEffect(() => {
  //   if (data) {
  //     const locationDropdownData = removeDuplicate(
  //       data,
  //       (allData) => allData.City
  //     );
  //     const truckDropdownData = removeDuplicate(
  //       data,
  //       (allData) => allData.Truck
  //     );
  //     if (!locationData.length) {
  //       setLocationData(locationDropdownData);
  //       setTruckData(truckDropdownData);
  //     }
  //   }
  // }, [data]);

  const modalRef = useRef(null);
  const [action, setAction] = useState("");

  const closeModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "none";
  };

  const showModal = (e) => {
    e.preventDefault();
    modalRef.current.style.display = "grid";
  };

  return (
    <DashboardLayout>
      {/* OCCUPANCY MODAL */}

      <div
        ref={modalRef}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "none",
          placeItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9000,
        }}
      >
        <div
          style={{
            width: 450,
            height: 450,
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: "3rem",
          }}
        >
          <p
            style={{
              fontSize: "2.5rem",
              color: "#000",
              marginBottom: "1.5rem",
              fontWeight: "bolder",
            }}
          >
            {action === "exporting" ? "Send" : "Download"} report
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            From: {dateFilter.split("&")[0].replace("period[start]=", "")}
          </p>

          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            To: {dateFilter.split("&")[1].replace("period[end]=", "")}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            For{" "}
            {truckFilter
              ? truckFilter.replace("truck=", "").split(",").length > 1
                ? `trucks: ${truckFilter
                    .replace("truck=", "")
                    .split(",")
                    .join(", ")}`
                : `truck: ${truckFilter.replace("truck=", "")}`
              : "all trucks"}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              color: "#000",
              marginBottom: "1rem",
              fontWeight: "500",
            }}
          >
            At{" "}
            {locationFilter
              ? `${
                  locationFilter.replace("location=", "").split(",").length > 1
                    ? `locations: ${locationFilter
                        .replace("location=", "")
                        .split(",")
                        .join(", ")}`
                    : `location: ${locationFilter.replace("location=", "")}`
                }`
              : "all locations."}
          </p>
          <div className="action" style={{ marginTop: "2rem" }}>
            {action === "exporting" ? (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const user = MapTokenToUser();
                  const data = {
                    export: {
                      entity: "occupancy_journal",
                      query: {
                        period: {
                          start: startDate,
                          end: endDate,
                        },
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "email",
                      recipients: [user.user.email],
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Send
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: Theme.colors.primaryColor,
                  padding: "0.8rem 1.7rem",
                  color: "#fff",
                  fontFamily: "inherit",
                  borderRadius: "0.4rem",
                  minWidth: 120,
                  marginRight: "1rem",
                  fontSize: "1.4rem",
                }}
                onClick={(e) => {
                  const data = {
                    export: {
                      entity: "occupancy_journal",
                      query: {
                        period: {
                          start: startDate,
                          end: endDate,
                        },
                        truck: truckDownload,
                        location: locationDownload,
                      },
                      as: "download",
                    },
                  };

                  getCSVExport(data);
                  closeModal(e);
                }}
              >
                Continue to download
              </button>
            )}
            <button
              style={{
                backgroundColor: "red",
                padding: "0.8rem 1.7rem",
                color: "#fff",
                fontFamily: "inherit",
                borderRadius: "0.4rem",
                minWidth: 120,
                fontSize: "1.4rem",
              }}
              onClick={(e) => closeModal(e)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      {/* OCCUPANCY MODAL */}
      <StyledDashboardContentWrapper>
        <PageHeaderLayout>
          <StyledDivFlex
            gap="1rem"
            flexDirectionSd="column"
            widthSd="100%"
            paddingS="2rem"
          >
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("exporting");
              }}
            >
              {isExporting ? "Sending..." : " Send Report to my Email"}
            </StyledPageHeaderButton>
            <StyledPageHeaderButton
              fontSizeSd="1.2rem"
              onClick={(e) => {
                showModal(e);
                setAction("downloading");
              }}
            >
              {" "}
              {isLoadingDownload ? "Downloading..." : "Download Report"}
            </StyledPageHeaderButton>
          </StyledDivFlex>
        </PageHeaderLayout>

        <StyledDivFlex
          // background={Theme.colors.neutralColor}
          padding="1rem 8rem"
          // marginTop="2rem"
          justifyContent="flex-end"
          gap="4rem"
          alignItems="center"
          paddingM="1rem 0"
          gapM="1.5rem"
          justifyContentM="center"
          flexDirectionS="column"
        >
          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            value={
              locationFilter?.includes("location=") &&
              locationFilter.replace("location=", "")
            }
            name="location"
            label="Location"
            onChange={(data) => {
              const { location } = data;
              const filter = location && `location=${location}`;
              setLocationFilter(filter);
              resetPagination();
              setLocationDownload(location);
              setSelectedLocations([...selectedLocations, location]);
            }}
            data={locationsDropdownData}
            gap="2rem"
            minWidth="22rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            multiSelect={true}
          />
          <PickDate
            onChange={(date) => {
              if (!date) {
                let endDate = new Date();
                let endDateMill = endDate.getTime();
                let interVal = 31 * 24 * 60 * 60 * 1000;
                let diff = endDateMill - interVal;
                let startDate = new Date(diff);
                date = [startDate, endDate];
              }
              const filter =
                date &&
                `period[start]=${
                  formatDate(date[0])["yyyy-mm-dd"]
                }&period[end]=${formatDate(date[1])["yyyy-mm-dd"]} 
             `;
              setDateFilter(filter);
              setDateRange(date);
              resetPagination();
              setStartDate(formatDate(date[0])["yyyy-mm-dd"]);
              setEndDate(formatDate(date[1])["yyyy-mm-dd"]);
            }}
            widthS="90%"
            loading={isLoading}
          />

          <Dropdown
            // background={Theme.colors.secondaryColor}
            isLoading={isLoading}
            value={
              truckFilter?.includes("truck=") &&
              truckFilter.replace("truck=", "")
            }
            name="truck"
            label="Filter Truck"
            onChange={(data) => {
              const { truck } = data;
              // const filter = truck ? `data.Truck:=:${truck}` : "";
              const filter = truck && `truck=${truck}`;
              resetPagination();
              setCurrentTruck((prev) => (truck ? truck : ""));
              setTruckFilter(filter);
              setTruckDownload(truck);
              // getOccupancyJournal(filter);
            }}
            data={truckDropdownData.filter((x) => {
              if (
                selectedLocations.includes(x.city) ||
                selectedLocations.length == 0
              ) {
                return x;
              }
            })}
            gap="2rem"
            minWidth="20rem"
            widthS="90%"
            icon={
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{ color: "#606060" }}
              />
            }
            multiSelect={true}
          />
        </StyledDivFlex>
        <StyledBox background={Theme.colors.neutralColor}>
          {/* <SpinningLoader /> */}
          <SubHeaderLayout
            current={currentTruck}
            text="Occupancy Journal for the period:"
            dateRange={dateRange}
            count={data?.length}
            data={data}
          />
        </StyledBox>
        <StyledBox>
          {isLoading ? (
            <SpinnerWithText
              isLoading={isLoading}
              margin="1rem 0 0 0"
              spinnerText="Kindly wait. This can take up to 2mins..."
            />
          ) : (
            <JournalTable data={data} />
          )}

          <Paginations
            totalPages={totalPages}
            getData={getOccupancyJournal}
            isLoading={isLoading}
            data={data}
            onPageSelected={setPageFilter}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </StyledBox>
      </StyledDashboardContentWrapper>
    </DashboardLayout>
  );
};

export default OccupancyJournal;
