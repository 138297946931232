import React, { useState, useEffect, useMemo } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { StyledTable } from "../../../../components/common/Basics/StyledTable";
import { StyledBox } from "../../../../components/common/Basics/DivBox";
import { ColumnFilter } from "../../../../components/common/Basics/ColumnFilter";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { StyledTextHeading } from "../../../../components/common/Basics/Heading";

const DriversTable = ({ data }) => {
  const [driverData, setDriverData] = useState([]);

  useEffect(() => {
    if (data) {
      const newData = data.map((data) => {
        data["First name"] = data.data["First name"];
        data["Last name"] = data.data["Last name"];
        data.RFID = data.data.RFID;
        data["RFID status"] = data.data["RFID status"];
        data.Role = data.data.Role;
        data.Trucks = data.data.Trucks;

        return data;
      });

      setDriverData(newData);
    }
  }, [data]);

  const COLUMN = [
    // {
    //   Header: "Id",
    //   accessor: "id",
    //   Filter: ColumnFilter,
    //   disableFilters: true,
    // },
    { Header: "Fullnames", accessor: "First name", Filter: ColumnFilter },
    {
      Header: "Site",
      accessor: "Last name",
      Filter: ColumnFilter,
    },
    { Header: "RFID Key", accessor: "RFID", Filter: ColumnFilter },
    { Header: "RFID Status", accessor: "RFID status", Filter: ColumnFilter },
    { Header: "Role", accessor: "Role", Filter: ColumnFilter },
    { Header: "Assigned Trucks", accessor: "Trucks", Filter: ColumnFilter },
  ];

  const columns = useMemo(() => COLUMN, []);
  const newDriverData = useMemo(() => driverData, [driverData, data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data: newDriverData,
        columns: columns,
      },

      useFilters,
      useSortBy
    );

  return (
    <StyledBox
      padding="1rem 8rem"
      style={{ maxWidth: "100%", overflowX: " auto " }}
    >
      {driverData?.length ? (
        <StyledTable {...getTableProps()} width="100%">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index.toString()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={i.toString()}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDropDownIcon fontSize="large" />
                        ) : (
                          <ArrowDropUpIcon fontSize="large" />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                    <div className="input-filter">
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              // console.log(row.values['First name']);
              prepareRow(row);

              if (
                row?.values["First name"]?.toLowerCase().includes("lagos") ||
                row?.values["First name"]?.toLowerCase().includes("ota") ||
                row?.values["First name"]?.toLowerCase().includes("ibadan")
              ) {
                return <></>;
              } else {
                return (
                  <tr {...row.getRowProps()} key={index.toString()}>
                    {row.cells.map((cell, index) => {
                      // console.log(cell.value)
                      return (
                        <td {...cell.getCellProps()} key={index.toString()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </StyledTable>
      ) : (
        <StyledTextHeading
          color="grey"
          textAlign="center"
          fontSize="2rem"
          paddingTop="1rem"
        >
          No Result found
        </StyledTextHeading>
      )}
    </StyledBox>
  );
};

export default DriversTable;
